var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.loaded
      ? _c(
          "div",
          { staticClass: "d-flex justify-content-center mb-3" },
          [_c("ct-centered-spinner")],
          1
        )
      : _c(
          "div",
          [
            _c(
              "b-card",
              [
                _vm.bundleIsNotCompatible
                  ? _c("b-card-text", [
                      _c("h3", [
                        _vm._v("\n          Problem detected\n        "),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n          It looks like this bundle is not compatible with the entity type of the company you have selected.\n        "
                        ),
                      ]),
                    ])
                  : _vm.availableFilingProducts.length > 0
                  ? _c(
                      "b-card-text",
                      [
                        _c("h3", [
                          _vm._v(
                            "\n          Filing Options for this Bundle\n        "
                          ),
                        ]),
                        _vm._l(
                          _vm.availableFilingProducts,
                          function (product, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                index === _vm.currentTab
                                  ? _c("filing-options", {
                                      attrs: {
                                        "is-displaying-bundle": true,
                                        "bundle-base-price": _vm.bundle.price,
                                        "submission-label":
                                          index ===
                                          _vm.availableFilingProducts.length - 1
                                            ? "Add to Cart"
                                            : "Next",
                                        product: product,
                                        company: _vm.company,
                                        "saved-items": _vm.selectedFilings,
                                        "allow-multi-jurisdiction": false,
                                      },
                                      on: {
                                        "remove-by-id":
                                          _vm.removeBundleFromCart,
                                        submit: _vm.addSelectionToSaved,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          }
                        ),
                        _c("br"),
                        _c("p", [
                          _vm._v("\n          Included in Bundle\n        "),
                        ]),
                        _c(
                          "ul",
                          [
                            _vm._l(_vm.bundle.products, function (item, i) {
                              return _c("li", { key: i }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.name) +
                                    "\n          "
                                ),
                              ])
                            }),
                            _vm.raIncluded
                              ? _c("li", [
                                  _vm._v(
                                    "\n            Registered Agent Service\n          "
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      2
                    )
                  : [
                      _c("b-card-text", [
                        _c(
                          "h3",
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.bundle.name) +
                                " - " +
                                _vm._s(_vm._f("dollars")(_vm.bundle.price)) +
                                "\n            "
                            ),
                            _c("ach-warning", {
                              attrs: { "cart-item": _vm.bundle },
                            }),
                          ],
                          1
                        ),
                        _c("br"),
                        _c("p", [
                          _vm._v(
                            "\n            Included in Bundle\n          "
                          ),
                        ]),
                        _c(
                          "ul",
                          [
                            _vm._l(_vm.bundle.products, function (item, i) {
                              return _c("li", { key: i }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.name) +
                                    "\n              "
                                ),
                                item.is_free_trial
                                  ? _c("span", [
                                      _vm._v("\n                - "),
                                      _c("i", [_vm._v("Free Trial")]),
                                    ])
                                  : _vm._e(),
                              ])
                            }),
                            _vm.bundle.ra_product_count > 0
                              ? _c("li", [
                                  _vm._v(
                                    "\n              Registered Agent Service\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right mt-1",
                          attrs: {
                            variant: "primary",
                            "aria-label": "add to cart button",
                            disabled: _vm.bundleInCart,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.submitBundle()
                            },
                          },
                        },
                        [_vm._v("\n          Add to Cart\n        ")]
                      ),
                    ],
              ],
              2
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }